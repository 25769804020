import React from 'react'

function Footer() {
    return (
        <footer>
            <section>
                This is the footer
            </section>
        </footer>
    )
}

export default Footer
